.container-page-project {
  padding: 0rem 6rem;
  
.page-projects {
  
    color: $primary-color;
   
    h1 {
        color: $primary-color;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem; /* 100% */
        letter-spacing: -0.1rem;
        overflow: hidden;
        white-space: nowrap;
        animation: slideIn 1s ease-out;
      }
    
      h2 {
        margin-bottom: 2rem;
        color: #71717a;
    
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
      }

      h3 {
        font-size: 20px;
        letter-spacing: -0.03rem;
        font-weight: 700;
      }

      h4 {
        font-size: 14px;
        color: #939DB8;
      }

      .status-container {
        display: flex;
        align-items: center;
    }

      .status-en-cours {
        color: #F8A300;
        font-size: 14px;
      }

      .status-to-configure {
        color: #6A74F3;
        font-size: 13px;
      }
      
      .status-configure {
        color: #23C273;
        font-size: 13px;
      }
      
      .status-cloture {
        color: $error;
        font-size: 13px;
      }

      .status-en-attente {
        color: #868E96;
        font-size: 13px;
      }

      .status-updateat {
        display: flex;
        align-items: center;
        line-height: 2rem;
        h4 {
          margin-left: 7px;
        }
      }

      .container-project-info {
       
        width: 100%;

      }

      .container-title-info {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
       
        .title-and-info-first {
          display: flex;
          flex-direction: column;

        }
        .title-and-info {
          display: flex;
          flex-direction: column;
          
        }
       
      }

      .container-grid {
        margin-top: 2rem;
      }

      .title {
        font-size: 12px;
        padding-left: 1rem;
        font-weight: 400;
        opacity: 80%;
        padding-top: 1rem;
        text-transform: uppercase;
      }

      .info {
        padding-left: 1rem;
        font-size: 18px;
        font-weight: 600;
        margin-top: 0.3rem;
      }

      .title-and-info-last {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        
      }
      .container-events {
       margin-top: 3rem;

       .th-name {
        width: 15%;
       }
       .th-trigger {
        width: 20%;
       }
       .th-comment {
        width: 35%;
       }
       .th-priority {
        width: 5%;
       }
       .th-status {
        width: 15%;
       }
       .th-actions {

        width: 3%;
       }
      }

      .container-title-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button {
          //font-family: 'InterMedium';
          margin-left: 16px;
          //font-size: 14px;
          //border-radius: 4px;
          //border: none;
          //padding: 0.5rem;
          //cursor: pointer;
        }

        // .btn-add {
        //   background-color: $action-color;
        //   &:hover {
        //     background-color: #6f78f5;
        //   }
        // }
        // .btn-export {
        //   background-color: #202020;
        //   color: white;
        // }
      }

      .td-actions {
        display: flex;
      }

      table {
        //background-color: #0F0F1B;
        text-align: left;
        margin-top: 2rem;
        margin-bottom: 3rem;
        width: 100%;
        background-color: #09081C;
        border: 1px solid rgba(34, 40, 56, 0.5);
        border-radius: 10px;
        padding: 2rem;
        
        thead {
          border: none;
            tr {
              border: none;
            }
        }
        
        // tbody {
        //   //background-color: #1A1D26;
        //     border: 1px solid rgba(255, 255, 255, 0.055);
        //     tr {
        //       //background-color: #222837;
        //     }
        // }
        tr {
          border: none;
         // background-color: #222837;
         //border-bottom: 1px solid #e5e7eb14;
        }

        tbody {
          tr:hover {
            background-color: #0f0d23;
          }
        }

        th {
          padding: 1.5rem;
          height: 40px;
          font-size: .75rem;
          line-height: 1rem;
          font-weight: 500;
          //text-transform: uppercase;
          color: #a1a1aa9f;
          vertical-align: middle;
          text-transform: uppercase;
        
        }
        td {
          padding-bottom: .5rem;
          padding-top: .5rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          vertical-align: middle;
          color: rgba(255, 255, 255, 0.80);
          height: 49px;
          font-size: 13px;
          font-weight: 400;
          max-width: 20rem;

            a {
              text-decoration:underline;
              color: white;
              font-weight: 600;
            }
        }
    }
}

.grid {

}

.grid-col {
  background-color: #1A1D26;
border: 1px solid rgba(255, 255, 255, 0.055);
border-radius: 5px;
margin: 10px;
height: 100px;
}

}
.homepage {
    padding: 0rem 6rem;
    color: $primary-color;
    display: flex;
    flex-direction: column;
  

    @keyframes slideIn {
      from {
        transform: translateX(-5vw);
      }
      to {
        transform: translateX(0);
      }
    }

  
    h1 {
      color: $primary-color;
      font-size: 2.1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3rem; /* 100% */
      letter-spacing: -0.10rem;
      overflow: hidden;
      white-space: nowrap;
      animation: slideIn 1s ease-out;
    }
  
    h2 {
      margin-bottom: 2rem;
      color: #71717a;
  
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.02625rem;
    }

    .search-bar {
      width: 18.8rem;
      margin-bottom: 2rem;
      input {
        background-color: $input-background;
        border: 1px solid $input-border;
      }
    }
    
    .project-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;

      .mantine-Menu-dropdown {
        background-color: #09081C;
        border: 1px solid rgba(34, 40, 56, 0.5);
      }

      .title-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .container-links {
        text-decoration: none;
      }
       .card {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        max-width: 320px;
        height: auto;
        padding: 25px;
        background-color: #09081C;
        //background-image: url("../../assets/back-card.svg"); 
        text-align: left;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid #15151F;
    
        &:hover {
          background-color: #141721;
          border-color: #232334;
        }

        .title2 {
          text-decoration: initial;
          color: #CBC9C9;
          font-weight: 500;
          font-size: 15px;
          margin-left: 8px;
          //margin-bottom: 6px;
        }

        a {
          text-decoration: none !important;
          //text-decoration-line:line-through;
        }
            span {
              
              color: white;
              font-weight: 600;
              font-size: 18px;
              margin-bottom: 4px;
              
            }

            p {

            }

            .status-en-cours {
              color: #F8A300;
              font-size: 13px;
            }
            
            .status-configure {
              color: #23C273;
              font-size: 13px;
            }

            .status-to-configure {
              color: #6A74F3;
              font-size: 13px;
            }
            
            .status-cloture {
              color: $error;
              font-size: 13px;
            }

            .status-en-attente {
              color: #868E96;
              font-size: 13px;
            }

            ul {
              margin-top: 1rem;
              margin-bottom: 1rem;
            }
            

            li {
            
            color: rgba(255, 255, 255, 0.80);
            font-size: 14px;
            letter-spacing: -0.02625rem;
            }

            .created {
              font-size: 13px;
              color: rgb(148, 148, 148);
              font-weight: 400;
            }
        }
        
    }    
@media (min-width: 1650px) {
  .project-cards {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 2100px) {
  .project-cards {
    grid-template-columns: repeat(5, 1fr);
  }
}

}
  
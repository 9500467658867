.custom-event {

    padding: 0rem 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titles {
        width: 950px;
    }
    

    h1 {
        color: $primary-color;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem; /* 100% */
        letter-spacing: -0.1rem;
        overflow: hidden;
        white-space: nowrap;
        animation: slideIn 1s ease-out;
      }
    
      h2 {
        margin-bottom: 2rem;
        color: #71717a;
    
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
      }

      h3 {
        color: $primary-color;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.02625rem;
    }
    h4 {
        margin-bottom: 2rem;
        color: #71717a;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
    }

    .container-params {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;

        .left {
            width: 350px;
            color: white;
            h3 {
                font-size: 20px;
                font-weight: 600;
            }
            h4 {
                margin-bottom: 2rem;
                color: #71717a;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                letter-spacing: -0.02625rem;
            }
        }
        .right {
            width: 600px;

      
                .mantine-Input-input {
                    background-color: #09081C;
                    border: 1px solid rgba(34, 40, 56, 0.5);
                  }
        
                  .mantine-Select-dropdown {
                    background-color: #09081C;
                    border: 1px solid rgba(34, 40, 56, 0.5);
                  }
        
                  .mantine-1u6302u[data-selected] {
                    background-color: #3b5bdb;
                  }
       

            .input-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 12px;

            }
            label {
                color: white;
                font-size: .875rem;
                line-height: 160%;
            }
            input {
                width: 280px;
                color: $primary-color;
                background-color: $input-background;
                border: 1px solid $input-border;
            }

            textarea {
                color: $primary-color;
                background-color: $input-background;
                border: 1px solid $input-border;
            }

            .container-chip {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 10px;
                align-items: center;
                .chip {
                    width: 100px;
                    margin-right: 1rem;
                    .mantine-6tm0ki[data-variant="outline"] {
                        background-color: $secondary-color;
                    }
                
               
                }
            }
            
        }
      }

    .container-params-2 {
        display: flex;
        flex-direction: column;
        //width: 100%;

        .right-2 {
            // display: flex;
            // flex-direction: row;
            // overflow-y: scroll;
            // max-width: 50rem;
            // margin-bottom: 3rem;

            .label-container {
                display: flex;
                flex-direction: row;
                margin-bottom: 2rem;

                label {
                margin-right: 1.5rem;
                border-bottom: 1px solid white;
                width: 200px;
                }
            }
        }
        .parameter-container {
            display: flex;
            align-items: center;
        //     background-color: #1C2029;
        //     border: 1px solid #373F4F;
        //     padding: 1rem;
        //     width: 20rem;
        //     margin-right: 1rem;

        //     .container-cross {
        //         display: flex;
        //         justify-content: flex-end;
        //     }
        input, .mantine-1m3pqry {
            width: 200px;
            color: $primary-color;
            //margin-right: 3rem;
            margin-bottom: 0.55rem;

            
           
        }
        }


        .input-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
        }
        label {
            color: white;
            font-size: .875rem;
            line-height: 160%;
        }
        input {
            width: 280px;
            color: $primary-color;
            background-color: $input-background;
            border: 1px solid $input-border;
        }

        textarea {
            color: $primary-color;
            background-color: $input-background;
            border: 1px solid $input-border;
        }
        
      }

      .div-button {
        margin-top: 2rem;
        button {
            color: white;

            font-size: .875rem;
            //width: 100%;
            
            cursor: pointer;
            
            background: $action-color;
        }
    }

    .container-btn {
        display: flex;
        justify-content: center;
    }
}
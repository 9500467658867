:root {
    /* ===== Colors ===== */
    --body-color: #0F0F1B;
    --sidebar-color: linear-gradient(180deg, rgba(1,2,16,1) 0%, rgba(1,2,16,1) 42%, rgba(116,91,181,0.1558998599439776) 100%);
    --primary-color: white;
    --primary-color-light: #f6f5ff;
    --toggle-color: #ddd;
    --text-color: #ffffff;
  
    /* ====== Transition ====== */
    --tran-03: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
  }
  
  .aside-navsidebar {
    background: var(--sidebar-color);
    border-right: 1px solid rgba(255, 255, 255, 0.03);
    z-index: 100;
    transition: var(--tran-05);
    width: 16rem;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    left: 0;
    padding: 24px;
    transition: all 0.5s;
    display: flex;
    justify-content: top;
    align-items: center;
  
    .arrow {
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
      margin-top: 0.4rem;
      cursor: pointer;
      width: 250px;
    }

    // .line {
    //     margin-top: 24px;
    //     margin-bottom: 24px;
    //     width: 100%;
    // border-bottom: 1px solid #2D2F39; /* Couleur de la ligne, ici noire (#000) */
    // }

    .main {
      margin-top: 3rem;
        margin-left: 12px;
        font-size: 10px;
        color: rgba(255, 255, 255, 0.50);
        display: flex;
        justify-content: left;
        width: 100%;
        margin-bottom: 8px;
    }

    .project {
      margin-top: 3rem;
      margin-left: 12px;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.50);
      display: flex;
      justify-content: left;
      width: 100%;
      margin-bottom: 8px;
    }
  
    .nav-logo {
        display: flex;
        align-items: center;
        width: 100%;
       // justify-content: center;
        margin-top: 1rem;

        img {
          width: 2.813rem;
          cursor: pointer;
        }
      
  
      h1 {
        
        color: $primary-color;
        font-size: 1.3rem;
        margin-left: 10px;
        font-weight: 600;
        margin-bottom: 5px;
      }
  
      span {
        font-weight: 200;
      }
    }
  
    .nav-sidebar {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
    
      img {
        
        margin-left: 7px;
      }
  
      .nav-links {
        margin-left: 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
  
        letter-spacing: -0.03rem;
      }
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: white;
        padding: 0.5rem;
        width: 207px;
        margin-bottom: 8px;
        border-radius: 3px;
      }
    }
  
    footer {
      display: block;
      flex: 0 0 auto;
      margin-top: auto;
  
      .nav-links {
        margin-left: 0.7rem;
        font-size: 0.875rem;
        font-weight: 500;
  
        letter-spacing: -0.03rem;
      }
  
      img {
        
        margin-left: 7px;
      }
  
      .logout {
        padding: 0.5rem;
        text-decoration: none;
        display: flex;
        align-items: center;
        color: #EB3349;
        width: 207px;
        cursor: pointer;
  
        &:hover {
          
          background: $hover-primary;
        }
      }
    }
  
    .hover-link.active {
    
        color: white;
      background: $hover-primary;
      padding: 0.5rem;
      /* margin-right: 25px; */
      border-radius: 0.3rem;
      width: 207px;
    }
  
    .hover-link {
      padding: 0.5rem;
    }
  
    /* Pour le style au survol */
    .hover-link:hover {
      
      background: $hover-primary;
      //     padding: 0.5rem;
      // margin-right: 25px;
      // border-radius: 0.5rem;
    }
  }
  
  .aside-close {
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    padding: 20px;
    transition: all 0.5s;
    min-width: 6rem;
    z-index: 2234;
    align-items: center;
    background: var(--sidebar-color);
    border-right: 1px solid rgba(255, 255, 255, 0.03);
    height: 100vh;
    justify-content: top;
    align-items: center;
  
    .arrow {
      margin-bottom: 2rem;
      cursor: pointer;
      
    }

    // .line {
    //     margin-top: 24px;
    //     margin-bottom: 24px;
    //     width: 100%;
    // border-bottom: 1px solid #2D2F39; /* Couleur de la ligne, ici noire (#000) */
    // }

    .main {
      margin-top: 3rem;
        display: flex;
      justify-content: center;
        font-size: 10px;
        color: rgba(255, 255, 255, 0.50);
        width: 100%;
        margin-bottom: 8px;
    }

    .project {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
        font-size: 10px;
        color: rgba(255, 255, 255, 0.50);
        width: 100%;
        margin-bottom: 8px;
    }
  
    .nav-sidebar {
      display: flex;
      flex-direction: column;
    
  
      img {
        
      }
  
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: #989898;
        margin-bottom: 3px;
        justify-content: center;
        &:active {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(353deg) brightness(103%) contrast(101%);
        }
      }
    }
    .text {
      opacity: 0;
    }
  
    header {
      .toggle {
        transform: translateY(-50%);
      }
    }
    .menu-title {
      opacity: 0;
    }
  
    .text-disable {
      display: none;
    }
  
    .display-none {
      display: none;
    }
  
    footer {
      display: block;
      flex: 0 0 auto;
      margin-top: auto;
  
      .nav-links {
        margin-left: 0.5rem;
        font-size: 0.875rem;
        font-weight: 500;
      }
  
      .logout {
        padding: 15px 15px;
        text-decoration: none;
        display: flex;
        align-items: center;
        color: #989898;
  
        cursor: pointer;
  
        &:hover {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(353deg) brightness(103%) contrast(101%);
          background: rgba(82, 82, 249, 0.02);
        }
      }
    }
  
    .hover-link.active {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(353deg) brightness(103%) contrast(101%);
      background: rgba(82, 82, 249, 0.05);
      padding: 15px 15px;
      color: white;
      border-radius: 0.5rem;
    }
  
    .hover-link {
      padding: 15px 15px;
    }
  
    /* Pour le style au survol */
    .hover-link:hover {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(353deg) brightness(103%) contrast(101%);
      background: rgba(82, 82, 249, 0.02);
      padding: 15px 15px;
      color: white;
      border-radius: 0.5rem;
    }
  }
  
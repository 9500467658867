.container-login {
    display: flex;
    background: radial-gradient(circle, rgb(31, 36, 45) 0%, rgba(11,11,21,1) 100%);
    height: 100vh;
  
    .left-container {
      padding: 0 6.3rem;
      width: 45rem;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 6rem;
      border-right: 1px solid rgb(24, 24, 24);
      height: 100%;
    }
  
    .titles {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 5.5rem;
  
      h1 {
        font-size: 2rem;
  
        font-weight: 600;
      }
  
      h2 {
        color: #909090;
  
        font-size: 0.9375rem;
  
        font-weight: 400;
  
        letter-spacing: -0.04688rem;
      }
  
      span {
        font-weight: 200;
      }
  
      h2 {
        color: #909090;
        font-size: 1rem;
        font-family: "InterRegular";
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.04688rem;
      }
    }
  
    .form {
      display: flex;
      flex-direction: column;
    }
  
    .right-container {
      width: 100%;
      background-repeat: no-repeat;
      background-origin: border-box;
      background-size: cover;
      background-image: url("../../assets/background-login.png");
  
      .container-rd {
        display: flex;
        justify-content: right;
        max-height: 100vh;
        padding-right: 2rem;
        height: 90%;
      }
      .yn-group-div {
        display: flex;
        justify-content: right;
        padding: 2rem;
      }
  
      .rd-div {
        display: flex;
        padding: 2rem;
        flex-direction: column;
        justify-content: end;
        text-align: right;
        p {
          color: #fff;
          font-family: "InterRegular";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.04688rem;
        }
        span {
          //color: $primary-color;
          font-family: "InterRegular";
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.04688rem;
        }
      }
    }
    .container-ask-account {
      display: flex;
      justify-content: center;
      flex-direction: row;
      .ask-account {
        position: fixed;
        bottom: 100px;
        span {
          color: #7e7e7e;
  
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.04688rem;
        }
  
        a {
          color: white;
  
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.04688rem;
          //  text-decoration-line: underline;
        }
      }
    }
  }
  
.config-event {
    padding: 0rem 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;


    h1 {
        color: $primary-color;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem; /* 100% */
        letter-spacing: -0.1rem;
        overflow: hidden;
        white-space: nowrap;
        animation: slideIn 1s ease-out;
      }
    
      h2 {
        margin-bottom: 2rem;
        color: #71717a;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
      }

      h3 {
        color: $primary-color;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.02625rem;
        
    }
    h4 {
        margin-bottom: 2rem;
        color: #71717a;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
    }

    .right {
        .mantine-Input-input {
            background-color: #09081C;
            border: 1px solid rgba(34, 40, 56, 0.5);
          }

          .mantine-Select-dropdown {
            background-color: #09081C;
            border: 1px solid rgba(34, 40, 56, 0.5);
          }

          .mantine-1u6302u[data-selected] {
            background-color: #3b5bdb;
          }
    }

    .container-params-2 {
        display: flex;
        flex-direction: column;
        width: 100%;

        .right-2 {
            // display: flex;
            // flex-direction: row;
            // overflow-y: scroll;
            // max-width: 50rem;
            // margin-bottom: 3rem;
            .mantine-Input-input {
                background-color: #09081C;
                border: 1px solid rgba(34, 40, 56, 0.5);
              }
    
              .mantine-Select-dropdown {
                background-color: #09081C;
                border: 1px solid rgba(34, 40, 56, 0.5);
              }
    
              .mantine-1u6302u[data-selected] {
                background-color: #3b5bdb;
              }

            .label-container {
                display: flex;
                flex-direction: row;
                margin-bottom: 2rem;

                label {
                margin-right: 1.5rem;
                border-bottom: 1px solid white;
                width: 200px;
                }
            }
        }
        .parameter-container {
            display: flex;
            align-items: center;
        //     background-color: #1C2029;
        //     border: 1px solid #373F4F;
        //     padding: 1rem;
        //     width: 20rem;
        //     margin-right: 1rem;

        //     .container-cross {
        //         display: flex;
        //         justify-content: flex-end;
        //     }
        input, .mantine-1m3pqry {
            width: 200px;
            color: $primary-color;
            //margin-right: 3rem;
            margin-bottom: 0.55rem;
           
        }
        }

        .input-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 12px;
        }
        label {
            color: white;
            font-size: .875rem;
            line-height: 160%;
        }
        input {
            width: 265px;
            color: $primary-color;
            background-color: $input-background;
            border: 1px solid $input-border;
        }

        textarea {
            color: $primary-color;
            background-color: $input-background;
            border: 1px solid $input-border;
            margin-bottom: 3rem;
        }
        
      }

    .container-config {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;

        .input-container {
            display: flex;
            flex-direction: row;
            margin-bottom: 12px;
            //max-width: 500px;
            input {
                width: 26.438rem;
            }
        }
        label {
            color: white;
            font-size: .875rem;
            line-height: 160%;
            margin-top: 7px;
        }

        input {
            
            color: $primary-color;
            background-color: $input-background;
            border: 1px solid $input-border;
        }
       

        textarea {
            color: $primary-color;
            background-color: $input-background;
            border: 1px solid $input-border;
        }

        .mantine-1b14538 {
            background-color: #222838;
        }

        .title-screenshot {
            margin-top: 2rem;
        }


        .div-comment {
            textarea {
                min-height: 5rem;
                width: 56rem;
            }
        }

        .preview {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 45px;
            align-items: center;
            max-width: 56rem;
            margin-top: 10px;
        }
        

        .container-screenshots {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 45px;
            align-items: center;
            max-width: 56rem;
            margin-top: 10px;
        }

        .screenshot {
        position: relative;
        display: inline-block;

        img {
            border-radius: 5px;
        }
        

            img.close-icon {
                position: absolute;
                top: 8px; /* Ajustez selon votre besoin */
                right: 8px; /* Ajustez selon votre besoin */
                width: 24px; /* Taille de l'icône */
                height: 24px; /* Taille de l'icône */
                cursor: pointer;
                z-index: 10; /* Assurez-vous que l'icône est au-dessus de l'image */
                
              }
        }
        
      }

      .container-add-btn {
        width: 56.063rem;
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
      }

      .container-datalayer {
        margin-top: 1rem;
        max-width: 56rem;
        // button {
        //     color: white;

        //     font-size: .875rem;
        //     line-height: 160%;
        //     border-width: 1px;
        //     border-color: transparent;
        //     cursor: pointer;
        //     white-space: nowrap;
        //     border-radius: 16px;
        //     padding-left: 22px;
        //     padding-right: 22px;
        //     padding-top: 8px;
        //     padding-bottom: 8px;
        //     background: radial-gradient(63.48% 77.49% at 52.87% -6.77%,rgba(255,255,255,0.4) 0%,rgba(255,255,255,0) 100%),linear-gradient(92.19deg,#5056ED 1.84%,#2AA4F5 99.51%),linear-gradient(0deg,#293040,#293040);
        // }

        .title-datalayer-btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        label {
            margin-top: 1rem;
            color: white;
            font-size: .875rem;
            line-height: 160%;
        }

        textarea {
            color: $primary-color;
            background-color: $input-background;
            border: 1px solid $input-border;
            min-height: 350px;
            margin-top: 5px;
        }
      }

     

      .div-button {
        margin-top: 2rem;
        button {
            //color: white;

            //font-size: .875rem;
       
            width: 100%;
          
            //cursor: pointer;
        
            
            // background: $action-color;
            // &:hover {
            //     background-color: #6f78f5;
            //   }
        }
    }

}
.generic-event {
    color: $primary-color;
    padding: 0rem 6rem;
    

    h1 {
        color: $primary-color;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem; /* 100% */
        letter-spacing: -0.15rem;
        overflow: hidden;
        white-space: nowrap;
        animation: slideIn 1s ease-out;
      }
    
      h2 {
        
        color: #71717a;
        
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
      }

      h3 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.02625rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
        text-transform: capitalize;
      }

      .h2-btn {
        display: flex;
        margin-bottom: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        // button {
        //     font-family: "InterMedium";
        //     margin-left: 16px;
        //     font-size: 14px;
        //     border-radius: 4px;
        //     border: none;
        //     padding: 0.5rem;
        //     cursor: pointer;
        //     background-color: $action-color;
        // }
      }

      .modal {
        p {
          font-size: 14px;
          font-family: "InterRegular";
        }
        .btn-div {
          padding: 1rem;
          width: 100%;
          display: flex;
          
        }
        
      }

     
      
      table {
        background-color: #09081C;
          border: 1px solid rgba(34, 40, 56, 0.5);
          padding: 2rem;
          width: 100%;
          
          thead {
            //border: 1px solid #e5e7eb14;
            border: none;
              tr {
                border: none;
              }
            }
          
          tbody {
            //background-color: #1A1D26;
            tr:hover {
              background-color: #0f0d23;
            }
    
            tr {
              border: none;
             // background-color: #222837;
             //border-bottom: 1px solid #e5e7eb14;
            }
          }
          .th-actions {
            text-align: right;
          }
  
         
        th {
          text-align: left;
          padding: 1.5rem;
          height: 40px;
          font-size: .75rem;
          line-height: 1rem;
          font-weight: 500;
          //text-transform: uppercase;
          color: #a1a1aa9f;
          vertical-align: middle;
          text-transform: uppercase;
        
        }
        td {
          padding-bottom: .5rem;
          padding-top: .5rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          vertical-align: middle;
          color: rgba(255, 255, 255, 0.80);
          height: 49px;
          font-size: 13px;
          font-weight: 400;
          max-width: 25rem;

            a {
              text-decoration:underline;
              color: white;
              font-weight: 600;
            }
        }
          .td-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
      }
}
.accueil {
    display: flex;
    justify-content: center;
    background: #010210;
    //background: radial-gradient(circle, rgb(31, 36, 45) 0%, rgba(11,11,21,1) 100%);
    height: 100vh;
    width: 100%;
    .content {
      flex-grow: 1;
      overflow: scroll;
    }
  
    .section-switch-nav {
      //display: flex;
  
      //width: 100%;
  
      justify-content: center;
      //padding-bottom: 5rem;
    }
  
    .div-switch {
      margin-top: 5.56rem;
      margin-bottom: 5.56rem;
    }
  }
  
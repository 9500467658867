.back-button {
    font-family: "InterMedium";
    display: flex;
    align-items: center;
    justify-content: left;
    background: none;
    color: #939DB8;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.875rem;
    width: 100%;
  
    &:hover {
      color: white;
      img {
        filter: brightness(0) invert(1);
      }
    }
  img {
    margin-right: 7px;
    width: 7px;
  }
  
  }
  
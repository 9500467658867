.error-msg {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0375rem;
  color: $error;
  margin-top: 5px;
}

.succes-msg {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0375rem;
  color: $sucess;
  margin-top: 5px;
}

.waiting-msg {
  color: $waiting;
}

.warning-msg {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0375rem;
  color: $error;
  margin-top: 5px;
}

.page-tracking-plan {
  padding: 0rem 3rem;
  background-color: #010210;
  
    h1 {
        color: $primary-color;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem; /* 100% */
        letter-spacing: -0.1rem;
        overflow: hidden;
        white-space: nowrap;
        animation: slideIn 1s ease-out;
        text-transform:capitalize;
      }
    
      h2 {
        margin-bottom: 2rem;
        color: #71717a;
    
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
        
      }

      .container-title-tracking {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        justify-content: space-between;
        
        .container-title-info {
          display: flex;
          align-items: center;
        }
        .title-tracking {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
         
  
          .details-project {
           
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.02625rem;
            span {
              color: #21B062;
            }
    
            p {
              color: #939DB8;
              margin-left: 10px;
            }
          }
  
          
        }
        
        .container-round {
          
          .round {
            width: 30px;
            height: 30px;
            background-color: #21B062;
            border-radius: 100px;
            border-image-outset: 1rem;
          }
        }

        .container-button-title {
          // button {
            
          //   border: 1px solid #484848;
          //   border-radius: 4px;
          //   padding: 5px 14px 5px 14px;
          //   font-family: "InterMedium";
          //   font-size: 0.975rem;
          //   cursor: pointer;
          //   background-color: $action-color;
          // &:hover {
          //   background-color: #6f78f5;
          // }
          // }

          img {
            width: 22px;
          }
        }
      }

     

      .container-event {
        
       //padding: 2.5rem 2.5rem;
        display: flex;
        flex-direction: column;
        //background-color: rgb(16 17 27 / 31%);
        text-align: left;
        margin-top: 2rem;
        margin-bottom: 3rem;
        width: 100%;
        //border: 1px solid #e5e7eb14;
        //border-radius: 0.2rem;
        .container-line{
          display: flex;
          width: 100%;
          justify-content: center;
          margin-top: 5rem;
          .line {
           
            color: rgba(255, 255, 255, 0.047);
            
           
          }
        }
        
      .table-container {
        table {
          width: 100%;
          margin-top: 1rem;
    //       background: linear-gradient(180deg, rgba(1,2,16,1) 0%, rgba(1,2,16,1) 42%, rgba(116,91,181,0.1558998599439776) 100%);
    // --primary-color: white;
          background-color: #09081C;
          border: 1px solid rgba(34, 40, 56, 0.5);
          padding: 2rem;

          tr:hover {
            background-color: #0f0d23;
          }

          .th-name {
            width:15%;
          }
         .th-type {
          width: 15%;
         }

         .th-value {
          width: 15%
         }

         .th-comment {

         }
          thead {
          //border: 1px solid #e5e7eb14;
          border: none;
            tr {
              border: none;
            }
          }

          tbody {
            //background-color: #1A1D26;
            
    
            tr {
              border: none;
             // background-color: #222837;
             //border-bottom: 1px solid #e5e7eb14;
            }
          }
        }
      }
       
        .datalayer {
          //width: fit-content;
          font-size: 12px;
          background-color: #1A1D26;
          border: 1px solid #373f4f;
          
        }

        .container-no-screenshot {
          width: 50%;
          margin-left: 0.5rem;
          // margin-left: 2rem;
          // margin-bottom: 8rem;
          background: #09081C;
          border: 1px solid rgba(34, 40, 56, 0.5);
          border-radius: 4px;
          padding: 1rem;
          padding-bottom: 4rem;
          overflow-y: hidden;
        }

          .container-screenshot {
            width: 50%;
            margin-left: 0.5rem;
            // margin-left: 2rem;
            // margin-bottom: 8rem;
            background: #09081C;
            border: 1px solid rgba(34, 40, 56, 0.5);
            border-radius: 4px;
            padding: 1rem;
            overflow-y: scroll;
            .container-img {
              display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 15px;
              // position: relative;
             // overflow:hidden;
              // margin:10px auto; /*pour centrer*/
              //  width:270px; /*par exemple*/
               //height:200px; /*par exemple*/
              
               .mantine-o542hc {
                 height: 100px !important;
                
               }

               .div-img-mantine {
                width: 150px !important;
                cursor: pointer;
                
               }

              
              img {
                // position: relative;
                // margin-bottom: -8rem;
                width: 150px;
                height: 150px;
                border: 1px solid #373f4f;
                //height: 170px;
              }
            }
          }

          
          .div-img {
             
            img {
              width: auto;
              height: 300px;
              object-fit: cover;
            }
          }

        th {
          padding: 1.5rem;
          height: 40px;
          font-size: .75rem;
          line-height: 1rem;
          font-weight: 500;
          //text-transform: uppercase;
          color: #a1a1aa9f;
          vertical-align: middle;
          text-transform: uppercase;
        
        }
        td {
          padding-bottom: .5rem;
          padding-top: .5rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          vertical-align: middle;
          color: rgba(255, 255, 255, 0.80);
          height: 49px;
          font-size: 13px;
          font-weight: 400;
          max-width: 20rem;

            a {
              text-decoration:underline;
              color: white;
              font-weight: 600;
            }
        }

        .event {
          font-size: 1.125rem;
          color: $primary-color;
          font-weight: 600;
          text-transform:capitalize;
        letter-spacing: -0.03rem;
        }

        .trigger {
          color: #A1A1AA;
          font-size: 15px;
          font-weight: 400;
        }

        .status {
          width: 200px;

          .mantine-Input-input {
            background-color: #09081C;
            border: 1px solid rgba(34, 40, 56, 0.5);
          }

          .mantine-Select-dropdown {
            background-color: #09081C;
            border: 1px solid rgba(34, 40, 56, 0.5);
          }

          .mantine-1u6302u[data-selected] {
            background-color: #3b5bdb;
          }
        }

        .container-event-title {
          display: flex;
          justify-content: space-between;
        }

        .container-datalayer {
          width: 50%;
          margin-right: 0.5rem;
          overflow: scroll;
        }

        .div-line {
          margin-top: 4rem;
          display: flex;
          width: 100%;
          justify-content: center;
        .line {
         
          width: 30%;
          border: 0.5px solid rgba(34, 40, 56, 0.6);
         
        }
        }


        .container-datalayer-img {
          display: flex;
          flex-direction: row;
          margin-top: 0.5rem;
         // padding-bottom: 2rem;

         .no-images-msg {
          display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-top: 2rem;
            font-size: 0.933rem;
          font-weight: 400;
          letter-spacing: -0.02625rem;
          overflow: hidden;
         }
         max-height: 15rem;
          .mantine-xlwgkm {
            // min-height: 10rem;
            // max-height: 23rem;
          }

          h5 {
            font-weight: 500;
            letter-spacing: -0.02625rem;
            font-size: 0.938rem;
            margin-bottom: 1rem;
            //margin-top: 1rem;
          }
        }
    }

    .container-recipe {
      background: #09081C;
    border: 1px solid rgba(34, 40, 56, 0.5);
    margin-top: 0.5rem;
    border-radius: 4px;
     .title-and-addrecipe {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.5rem 0rem 1.5rem;
      
      h5 {
        font-weight: 500;
            letter-spacing: -0.02625rem;
            font-size: 0.938rem;
      
      }
     }

          .label-container {
              display: flex;
              flex-direction: row;
              margin-bottom: 2rem;

              label {
              margin-right: 1.5rem;
              border-bottom: 1px solid white;
              width: 200px;
              }
          }

          .no-recipe-msg {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-bottom: 2rem;
            font-size: 0.933rem;
          font-weight: 400;
          letter-spacing: -0.02625rem;
          }
      
          .container-add-recipe {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 1rem;
    
            
            label {
                color: white;
                font-size: .875rem;
                line-height: 160%;
                margin-top: 7px;
            }
    
            .text-input {
                
                color: $primary-color;
                background-color: $input-background;
                border: 1px solid $input-border;
            }
           
    
            .select-input {
                color: $primary-color;
                background-color: $input-background;
                border: 1px solid $input-border;
            }
    
          }
    
     .table-recipe {
      width: 100%;

      tbody {
        tr:hover {
          background-color: #0f0d23;
        }
      }
     

      img {cursor: pointer;}
      
      .th-date {
        width: 15%;
      }
      .th-status {
        width: 15%;
      }
      .th-actions {
        width: 10%;
      }
     }
    }

    .status-container {
      display: flex;
      align-items: center;
  }

    .status-en-cours {
      color: #F8A300;
      font-size: 14px;
    }

    .status-to-configure {
      color: #6A74F3;
      font-size: 13px;
    }
    
    .status-configure {
      color: #23C273;
      font-size: 14px;
    }
    
    .status-cloture {
      color: $error;
      font-size: 14px;
    }

    .status-en-attente {
      color: #868E96;
      font-size: 13px;
    }

    .status-updateat {
      display: flex;
      align-items: center;
      line-height: 2rem;
      h4 {
        margin-left: 7px;
        color: #939DB8;
      }
    }

    .grid {
      margin-top: 3rem;
    }
    
    .grid-col {
      background: #09081C; 
    border: 1px solid rgba(255, 255, 255, 0.055);
    border-radius: 5px;
    margin: 10px;
    height: auto;
    padding: 2rem;
    }

    .title {
      font-size: 12px;
     
      font-weight: 400;
      opacity: 80%;
      
      text-transform: uppercase;
;
    }

    .info {
   
      font-size: 18px;
      font-weight: 600;
           
    } 

    .infos {
      font-size: 16px;
      font-weight: 500;
    }
    .container-show-table {
      display: flex;
      justify-content: center;
      margin-top: 0.3rem;
      align-items: center;
      cursor: pointer;
      font-size: 0.80rem;

      .container-span-img {
        display: "flex";
        flex-direction: "column";
        font-size: 14px;
        font-weight: 600;
        margin-top: 0.2rem;
        letter-spacing: -0.03rem;
        

        img {
          width: 24px;
        }
      }
    }
    
    // @media (max-width: 1250px) {
    //   .container-event {
    //     .container-img-title {
    //       margin-left: 2rem;
    //       margin-bottom: 2rem;
    //       .container-img {
    //         position: relative;
    //        // overflow:hidden;
    //         // margin:10px auto; /*pour centrer*/
    //          width:200px; /*par exemple*/
            

    //          .mantine-o542hc {
    //           height: 120px !important;
    //          }

    //          .div-img-mantine {
    //           width: 240px;
    //          }
            
    //         img {
    //           position: relative;
    //           margin-bottom: -6rem;
    //           border: 1px solid #373f4f;
            
    //         }
    //       }
    //     }

        
    //     .div-img {
           
    //       img {
    //         width: auto;
    //         height: 300px;
    //         object-fit: cover;
    //       }
    //     }
    //   }
    // }
  


    @media (max-width: 1350px) {
      .container-event {

        .mantine-img {
          margin-left: 0 !important;
        }
       
        .container-img-title {
          margin-left: 2rem;
          margin-bottom: 0rem;
          width: 50%;
          
          .container-img {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 15px;

             .mantine-o542hc {
              height: 90px !important;
             }

             .div-img-mantine {
              width: 100px !important;
             }
            
            img {
              margin-bottom: 0;
            
            }
          }
        }

        
        .div-img {
           
          img {
            width: auto;
            height: 300px;
            object-fit: cover;
          }
        }
      }
    }

    
}

@media (max-width: 1185px) {
  .container-img {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (min-width: 1750px) {
  .page-tracking-plan {
  padding: 0rem 7rem;
  
  } 
}

@media (min-width: 1950px) {
  .page-tracking-plan {
  padding: 0rem 9rem;
  } 
  .container-img {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-gap: 15px;

     .mantine-o542hc {
      height: 90px !important;
     }

     .div-img-mantine {
      width: 100px !important;
     }
    
    img {
      margin-bottom: 0;
    
    }
  }
}

@media (min-width: 2250px) {
  .page-tracking-plan {
  padding: 0rem 12rem;
  } 
  .container-img {
    display: grid;
    grid-template-columns: repeat(5, 1fr) !important;
    grid-gap: 15px;

  }
}

@media (min-width: 2450px) {
  .page-tracking-plan {
  padding: 0rem 17rem;
  } 
}

  .custom-modal {
    background-color:#010210 !important; // Background color
    color: white; // Text color for better contrast
    border-radius: 8px; // Optional: Rounded corners
    width: 40rem;
    padding: 3rem;
  }

.create-project {
    padding: 0rem 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titles {
        width: 950px;
    }
   
    h1 {
        color: $primary-color;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem;
        letter-spacing: -0.06rem;
        overflow: hidden;
        white-space: nowrap;
        animation: slideIn 1s ease-out;
      }

      .titles-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 950px;
        .titles {

        }
      }
    
      h2 {
        margin-bottom: 2rem;
        color: #71717a;
    
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
      }
      .container-params {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;

        .left {
            width: 350px;
            color: white;
            h3 {
                font-size: 20px;
                font-weight: 600;
            }
            h4 {
                margin-bottom: 2rem;
                color: #71717a;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                letter-spacing: -0.02625rem;
            }
        }
        .right {
            width: 600px;

            .mantine-Input-input {
                background-color: #09081C;
                border: 1px solid rgba(34, 40, 56, 0.5);
              }
    
              .mantine-Select-dropdown {
                background-color: #09081C;
                border: 1px solid rgba(34, 40, 56, 0.5);
              }
    
              .mantine-1u6302u[data-selected] {
                background-color: #3b5bdb;
              }

            h3 {
                font-weight: 600;
                color: white;
            }

            .title-tag {
                margin-top: 2rem;
                
            }

            .input-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 12px;
            }
            label {
                color: white;
                font-size: .875rem;
                line-height: 160%;
            }
            input {
                width: 280px;
                color: $primary-color;
                background-color: $input-background;
                border: 1px solid $input-border;
            }

            textarea {
                color: $primary-color;
                background-color: $input-background;
                border: 1px solid $input-border;
            }

            .chip-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 10px;
                align-items: center;
                width: 600px;

                
                .chip {
                    width: 120px;
                    .mantine-Chip-label {
                        color: white;
                        background: #5A63D3;
                        align-content: center;
                        font-weight: 600;
                       
                    }

                    .mantine-Chip-checkIcon {
                        color: white;
                        
                    }
                    

                    .mantine-Chip-root {
                        
                        background-color: white;
                        color: white;
                        border-color: white;
                    }
                  
                    margin-right: 1rem;
                    color: white;
                    
                }
            }

            .custom-fields-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;
                
                .name-cross {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 1rem;
                }

                textarea {
                    width: 100%;
                }
            }

            .container-add-btn {
                display: flex;
        justify-content: center;
            }

            
            .div-button {
                margin-top: 4rem;

                button {
                    width: 100%;
                    font-family: "InterMedium";
                    background-color: #5A63D3;
                    &:hover {
                        background-color: #6f78f5;
                      }
                }
                // button {
                //     color: white;

                //     font-size: .875rem;
                //     line-height: 160%;
                //     width: 100%;
                //     border-width: 1px;
                //     border-color: transparent;
                //     cursor: pointer;
                //     white-space: nowrap;
                //     border-radius: 16px;
                //     padding-left: 22px;
                //     padding-right: 22px;
                //     padding-top: 8px;
                //     padding-bottom: 8px;
                //     background: radial-gradient(63.48% 77.49% at 52.87% -6.77%,rgba(255,255,255,0.4) 0%,rgba(255,255,255,0) 100%),linear-gradient(92.19deg,#5056ED 1.84%,#2AA4F5 99.51%),linear-gradient(0deg,#293040,#293040);
                // }
            }
            
        }
      }
      
}
.create-event {
    padding: 0rem 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titles {
        width: 950px;
    }
    h1 {
        color: $primary-color;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem; /* 100% */
        letter-spacing: -0.1rem;
        overflow: hidden;
        white-space: nowrap;
        animation: slideIn 1s ease-out;
      }
    
      h2 {
        margin-bottom: 2rem;
        color: #71717a;
    
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
      }

      h3 {
        color: $primary-color;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.02625rem;
    }
    h4 {
        margin-bottom: 2rem;
        color: #71717a;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02625rem;
    }

    .mantine-Input-input {
        background-color: #09081C;
        border: 1px solid rgba(34, 40, 56, 0.5);
      }

      .mantine-Select-dropdown {
        background-color: #09081C;
        border: 1px solid rgba(34, 40, 56, 0.5);
      }

      .mantine-1u6302u[data-selected] {
        background-color: #3b5bdb;
      }

      .input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
    }
    label {
        color: white;
        letter-spacing: -0.02625rem;
    }
    input {
        color: white;
        width: 280px;
        background-color: $input-background;
        border: 1px solid $input-border;
        margin-bottom: 12px;
    }

    textarea {
        color: white;
        background-color: $input-background;
        border: 1px solid $input-border;
    }

      .container-params {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;

        .left {
            width: 350px;
            color: white;
            
        }
        .right {
            width: 600px;
            
        }
        
      }

      .container-params-2 {
        display: flex;
        flex-direction: column;
        width: 100%;

        .right-2 {
            // display: flex;
            // flex-direction: row;
            // overflow-y: scroll;
            // max-width: 50rem;
            // margin-bottom: 3rem;
            .mantine-Input-input {
                background-color: #09081C;
                border: 1px solid rgba(34, 40, 56, 0.5);
              }
    
              .mantine-Select-dropdown {
                background-color: #09081C;
                border: 1px solid rgba(34, 40, 56, 0.5);
              }
    
              .mantine-1u6302u[data-selected] {
                background-color: #3b5bdb;
              }

            .label-container {
                display: flex;
                flex-direction: row;
                margin-bottom: 2rem;

                label {
                margin-right: 1.5rem;
                border-bottom: 1px solid white;
                width: 200px;
                }
            }
        }
        .parameter-container {
            display: flex;
            align-items: center;
        //     background-color: #1C2029;
        //     border: 1px solid #373F4F;
        //     padding: 1rem;
        //     width: 20rem;
        //     margin-right: 1rem;

        //     .container-cross {
        //         display: flex;
        //         justify-content: flex-end;
        //     }
        input, .mantine-1m3pqry {
            width: 200px;
            color: $primary-color;
            //margin-right: 3rem;
            margin-bottom: 0.55rem;

            
           
        }
        }
      }
      
      .div-button {
        margin-top: 2rem;
        // display: flex;
        // justify-content: flex-end;
        button {
            color: white;
            font-weight: 600;
            letter-spacing: -0.02625rem;
            font-size: 14px;
            width: 100%;
            height: 33px;
            border: none;
            border-radius: 3px;
            background: $action-color;
        }
    }

    .container-btn {
        display: flex;
        justify-content: center;
    }
}
.title-standard {
    font-weight: 600;
    color: white;
}
.title-tag {
    margin-top: 2rem;
    font-weight: 600;
    color: white;
  }
  
  .chip-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    align-items: center;
    width: 600px;
  
    
    .chip {
        width: 120px;
        .mantine-Chip-label {
            color: white;
            background: #5A63D3;
            align-content: center;
            font-weight: 600;
           
        }
  
        .mantine-Chip-checkIcon {
            color: white;
            
        }
        
  
        .mantine-Chip-root {
            
            background-color: white;
            color: white;
            border-color: white;
        }
      
        margin-right: 1rem;
        color: white;
        
    }
  }
$primary-color: white;
$secondary-color: #0B0B15;
$tertiary-color: #e7e7e7;
$quaternary-color: #999ca6;
$hover-primary: #2D2F39;
//$quaternary-color: #b6c0ce;
$quinary-color: #333333;
$hover-primary-color: #1348db;
$background-color: #f8f9fa;
$stroke-color:#15151F;
$sucess: #4e8565;
$error: #dd5c64;
$waiting: #e5b687;
$input-background: #09081C;
$input-border: rgba(255, 255, 255, 0.055);
$action-color: #5B63D3;
@font-face {
  font-family: "InterLight"; /*Can be any text*/
  src: local("Inter-Light"),
    url("../../assets/fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "InterRegular"; /*Can be any text*/
  src: local("Inter-Regular"),
    url("../../assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "InterBold"; /*Can be any text*/
  src: local("Inter-Bold"),
    url("../../assets/fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "InterSemiBold"; /*Can be any text*/
  src: local("Inter-SemiBold"),
    url("../../assets/fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "InterMedium"; /*Can be any text*/
  src: local("Inter-Medium"),
    url("../../assets/fonts/Inter-Medium.ttf") format("truetype");
}
